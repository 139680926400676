import React, { useState, useEffect } from 'react';
import { DesktopOnly, MobileOnly } from '../../layouts/Footer';
import { cloudfrontBaseUrl } from '../../lib/consts';
import { Box } from '@chakra-ui/core';
import { animations } from '@theme';

const hero1 = `${cloudfrontBaseUrl}/invite/referralhero1.svg`;
const hero2 = `${cloudfrontBaseUrl}/invite/referralhero2.svg`;

const ImageRotator = () => {
  const [currentImage, setCurrentImage] = useState(hero1);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentImage === hero1) {
        setCurrentImage(hero2);
      }

      if (currentImage === hero2) {
        setCurrentImage(hero1);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [currentImage]);

  return (
    <>
      <DesktopOnly>
        <Box>
          {currentImage === hero1 && (
            <img css={animations.fade} style={{ height: '350px' }} src={currentImage} />
          )}
          {currentImage === hero2 && (
            <img css={animations.fade} style={{ height: '350px' }} src={currentImage} />
          )}
        </Box>
      </DesktopOnly>
      <MobileOnly>
        <Box>
          {currentImage === hero1 && (
            <img css={animations.fade} style={{ height: '230px' }} src={currentImage} />
          )}
          {currentImage === hero2 && (
            <img css={animations.fade} style={{ height: '230px' }} src={currentImage} />
          )}
        </Box>
      </MobileOnly>
    </>
  );
};

export default ImageRotator;
